/*
 * May 6, Raymond: finished the frontend and the design for this but have not tested with
 * the backend
 *
 * May 8, Andrew: added a functionality where users can check the password they are typing in the password box
 *
 * TODO
 * add functionality to the status codes, alert user about the result and redirect as needed
 */
import './styles/createProfile.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

function CreateProfile() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyRole, setCompanyRole] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  function signupComplete(event) {
    event.preventDefault();

    const helperMessage = document.getElementById('helper-message');
    function borderRed(element) {
      document.getElementById(element).style.border = '1px solid red';
    }

    if (!email) {
      borderRed('signup-email');
      helperMessage.innerHTML = 'missing email';
      return;
    }

    if (typeof email !== 'string') {
      borderRed('signup-email');
      helperMessage.innerHTML = 'invalid email input';
      return;
    }

    if (!email.includes('@')) {
      borderRed('signup-email');
      helperMessage.innerHTML = 'invalid email input (missing @)';
      return;
    }

    if (!password) {
      borderRed('signup-password');
      helperMessage.innerHTML = 'missing password';
      return;
    }

    if (!confirmPassword) {
      borderRed('signup-confirm');
      helperMessage.innerHTML = 'missing password';
      return;
    }

    if (!firstName) {
      borderRed('signup-first');
      helperMessage.innerHTML = 'missing first name';
      return;
    }

    if (!lastName) {
      borderRed('signup-last');
      helperMessage.innerHTML = 'missing last name';
      return;
    }

    if (password !== confirmPassword) {
      borderRed('signup-password');
      borderRed('signup-confirm');
      helperMessage.innerHTML = 'passwords do not match';
      return;
    }

    const data = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      companyRole: companyRole,
    };

    console.log('this is data', data);

    axios
      .post('https://buildpermit.ai:4000/signup', data)
      .then(async (res) => {
        //TODO if 201 is sent back then the user was created successfully and
        //the email was sent to verify
        if (res.status === 201) {
          console.log('user created successful');
          console.log(res);
          alert(
            'user created successful, click ok then check your email for a verification code'
          );
          navigate('/account-verification');
        }
        // TODO 409 means missing fields or the email/user already exists
        else if (res.status === 409) {
          console.log('hit 409 error');
          console.log(res.data);
          alert('user already exists, use another email');
        }
        // TODO 500 means something happened on the server
        else if (res.status === 500) {
          console.log('hit 500 error');
          console.log(res.data);
        }
        // TODO something else happened
        else {
          console.log('backend unable to create new user');
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
        if (!error.response) {
          helperMessage.innerHTML = 'an error has occured with our servers';
          helperMessage.style.color = '#333';
          return;
        }
        const errorStatus = error.response.status;
        if (errorStatus === 409) {
          helperMessage.innerHTML = error.response.data;
        } else {
          helperMessage.innerHTML = 'an error has occured with our servers';
          helperMessage.style.color = '#333';
        }
      });
  }

  return (
    <div id="create-profile-container">
      <div id="inner-create-profile-container">
        <section id="header-and-description">
          <h1 id="signup-header">Sign Up</h1>
          <h2 id="register-header">Register with your email</h2>
        </section>
        <form id="signup-form" onSubmit={signupComplete}>
          <input
            type="text"
            placeholder="Email*"
            className="signup-input"
            id="signup-email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <div id="password-container">
            <input
              type={visiblePassword ? 'text' : 'password'}
              placeholder="Password*"
              className="signup-input"
              id="signup-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            {visiblePassword ? (
              <EyeOutlined
                id="visible-icon"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            ) : (
              <EyeInvisibleOutlined
                id="visible-icon"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            )}
          </div>
          <div id="password-container">
            <input
              type={visibleConfirm ? 'text' : 'password'}
              placeholder="Confirm Password*"
              className="signup-input"
              id="signup-confirm"
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
            {visibleConfirm ? (
              <EyeOutlined
                id="visible-icon"
                onClick={() => setVisibleConfirm(!visibleConfirm)}
              />
            ) : (
              <EyeInvisibleOutlined
                id="visible-icon"
                onClick={() => setVisibleConfirm(!visibleConfirm)}
              />
            )}
          </div>
          <input
            type="text"
            placeholder="First Name*"
            className="signup-input"
            id="signup-first"
            onChange={(event) => setFirstName(event.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name*"
            className="signup-input"
            id="signup-last"
            onChange={(event) => setLastName(event.target.value)}
          />
          <input
            type="text"
            placeholder="Company Role"
            className="signup-input"
            id="signup-role"
            onChange={(event) => setCompanyRole(event.target.value)}
          />
          <section id="helper-message-container">
            <span id="helper-message"></span>
          </section>
          <button id="signup-button">Sign Up</button>
        </form>
        <a href="/" id="back-to-signin">
          {' '}
          &#60; Back to Sign In
        </a>
      </div>
    </div>
  );
}

export default CreateProfile;
