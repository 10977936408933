/*
 * May 8, Raymond:
 * TODO work on toggle switch to if the tag that is being toggled is not active then switch
 * else if it is active then don't switch to the other togle
 *
 * TODO make tile responsive (need to wait till navbar is also responsive)
 */
import './styles/projectTiles.css';
import React, { useEffect, useState } from 'react';
import blue_condo from '../../images/projects_condo_blue.svg';
import { useNavigate } from 'react-router-dom';
import ActiveProjects from './activeProjects.js';
import DraftProjects from './draftProjects.js';

function ProjectsTile() {
  // active projects will be true, draft projects will be false
  const [projects, setProjects] = useState(true);
  const navigate = useNavigate();

  function toggle() {
    setProjects(!projects);
  }

  function createProject() {
    navigate('/projects/create');
  }

  // used to change the colors and borders of active and draft tag
  // everytime one is clicked
  useEffect(() => {
    const active = document.getElementById('active-toggle');
    const draft = document.getElementById('draft-toggle');
    if (projects === true) {
      active.style.borderBottom = '1px solid #6950e8';
      active.style.color = '#6950e8';
      draft.style.border = 'none';
      draft.style.color = '#6b7280';
    } else {
      draft.style.borderBottom = '1px solid #6950e8';
      draft.style.color = '#6950e8';
      active.style.border = 'none';
      active.style.color = '#6b7280';
    }
  }, [projects]);

  return (
    <div id="projects-tile-container">
      <section id="top-line-projects-tile">
        <div id="condo-and-projects-container">
          <img
            src={blue_condo}
            alt="blue condo minimalistic icon"
            id="projects-blue-condo-img"
          />
          <h2 id="projects-tile-header">Projects</h2>
        </div>
        <div id="projects-tile-active-and-draft-container">
          <a
            href="#active"
            className="projects-toggle"
            id="active-toggle"
            onClick={toggle}
          >
            Active
          </a>
          <a
            href="#draft"
            className="projects-toggle"
            id="draft-toggle"
            onClick={toggle}
          >
            Draft
          </a>
        </div>
        <button id="projects-tile-add-project" onClick={createProject}>
          &#43; Add Project
        </button>
      </section>
      {/*
      TODO removed search bar for quick build, implement search through projects 
      functionality later
      <section id="projects-tile-search-bar-container">
        <input type="text" placeholder="Search" id="projects-tile-search-bar" />
      </section>
      */}
      <section id="all-projects-tile-container">
        <div id="inner-all-projects-tile-container">
          {projects ? <ActiveProjects /> : <DraftProjects />}
        </div>
      </section>
    </div>
  );
}

export default ProjectsTile;
