/* Andrew Park June 21, 2024
 * This component is used to display the billing page.
 */

import React from "react";
import Navbar from "../../components/Navbar/navbar.js";
import "./styles/invoice.css";
import InvoiceTile from "../../components/Invoice/invoiceTile.js";
import TopNav from "../../components/Navbar/topnav.js";

function Invoice() {
  return (
    <div id="invoice-container">
      <section id="main-navbar-invoice">
        <Navbar currentPage={"invoice"} />
      </section>
      <section id="top-nav-invoice">
        <TopNav />
      </section>
      <section id="invoice-tiles-container">
        <InvoiceTile id="invoice-tile-invoice" />
      </section>
    </div>
  );
}

export default Invoice;
