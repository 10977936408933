/**
 * May 8: Andrew: I added a functionality where users can check the password they are typing in the password box
 * by clicking on the eye icon.
 *
 * TODO
 * add functionality to remember me? checkbox
 *
 * TODO
 * show user alert boxes based on the different statuses that are returned by the backend
 */

import './styles/login.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';

function Login() {
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setPassword] = useState(null);
  const [remember, setRemember] = useState(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();

  // check to see if user has cookies, if so then log user in
  useEffect(() => {
    const token = localStorage.getItem('rememberToken');
    console.log('this is token ', token);
    token
      ? checkToken()
      : (document.getElementById('helper-message').innerHTML = '');

    function checkToken() {
      axios
        .get(`https://buildpermit.ai:4000/login/verify/${token}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            document.getElementById('helper-message').innerHTML = 'logged in';
            navigate('/projects');
          } else {
            localStorage.removeItem('rememberToken');
          }
        })
        .catch((error) => {
          console.log('no user', error);
          document.getElementById('helper-message').innerHTML = '';
          localStorage.removeItem('rememberToken');
        });
    }
  }, []);

  async function userLogin(e) {
    e.preventDefault();

    // check to make sure both email and password are put in
    if (!userEmail) {
      document.getElementById('helper-message').innerHTML = 'missing email';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email-input').style.border = '1px solid red';
      return false;
    }

    if (typeof userEmail !== 'string') {
      document.getElementById('helper-message').innerHTML =
        'invalid email input';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email-input').style.border = '1px solid red';
      return false;
    }

    if (!userEmail.includes('@')) {
      document.getElementById('helper-message').innerHTML =
        'invalid email input (missing @)';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('email-input').style.border = '1px solid red';
      return false;
    }

    if (!userPassword) {
      document.getElementById('helper-message').innerHTML = 'missing password';
      document.getElementById('helper-message').style.color = 'red';
      document.getElementById('password-input').style.border = '1px solid red';
      return false;
    }

    const loginData = {
      email: userEmail,
      password: userPassword,
      remember: remember,
    };

    const helperMessage = document.getElementById('helper-message');
    helperMessage.innerHTML = 'checking if email provided is verified...';
    helperMessage.style.color = '#333';
    const checkVerification = await axios
      .get(
        `https://buildpermit.ai:4000/confirmation/checkVerify/${userEmail}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log('checked user');
        return res;
      })
      .catch((error) => {
        console.log('unable to check verification');
        console.error(error);
        return false;
      });

    console.log('checked if verified:', checkVerification);

    helperMessage.innerHTML = 'logging in...';
    helperMessage.style.color = '#333';
    axios
      .post('https://buildpermit.ai:4000/login', loginData, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          if (checkVerification.status === 200) {
            console.log('this is full response:', response);
            if (remember) {
              const userToken = response.data.token;
              console.log('this is userToken', userToken);
              localStorage.setItem('rememberToken', userToken);
            }
            navigate('/projects');
            return;
          }
          alert('need to verify email to continue');
          navigate('/account-verification');
          return;
        }
      })
      .catch((error) => {
        console.log('this is error', error);
        const helperMessage = document.getElementById('helper-message');
        if (!error.response) {
          helperMessage.innerHTML = 'an error has occured with our servers';
          helperMessage.style.color = '#333';
          return;
        }
        if (error.response.status === 403) {
          helperMessage.innerHTML = error.response.data;
          helperMessage.style.color = 'red';

          document.getElementById('email-input').style.border = '1px solid red';
          document.getElementById('password-input').style.border =
            '1px solid red';
        } else if (error.response.status === 409) {
          helperMessage.innerHTML = error.response.data;
          helperMessage.style.color = '#2d8cff';
        } else {
          helperMessage.innerHTML = 'an error has occured with our servers';
          helperMessage.style.color = '#333';
        }
      });
  }

  return (
    <div className="login-container">
      <div id="inner-login-container">
        <div id="signin-signup-text">
          <h1>Sign In</h1>
          <p id="signup-text">
            New user?{' '}
            <a href="/signup" id="signup-link">
              <strong>Create an Account</strong>
            </a>
          </p>
        </div>
        <div className="form-container">
          <p id="form-title">Register</p>
          <form onSubmit={userLogin}>
            <div className="input-container">
              <input
                type="text"
                placeholder="Email"
                className="input"
                id="email-input"
                onChange={(event) => setUserEmail(event.target.value)}
              />
              <div id="password-container">
                <input
                  type={visible ? 'text' : 'password'}
                  placeholder="Password"
                  className="input"
                  id="password-input"
                  onChange={(event) => setPassword(event.target.value)}
                />
                {visible ? (
                  <EyeOutlined
                    id="visible-icon"
                    onClick={() => setVisible(!visible)}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    id="visible-icon"
                    onClick={() => setVisible(!visible)}
                  />
                )}
              </div>
            </div>
            <section id="helper-message-container">
              <span id="helper-message" style={{ color: '#333' }}>
                Checking if user is logged in...
              </span>
            </section>
            <section id="remember-container">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  id="keep-signed-in-checkbox"
                  onChange={() => setRemember(true)}
                />{' '}
                Remember me?{' '}
              </label>
              <a href="/account-recovery" id="account-recovery">
                Forgot Password?
              </a>
            </section>
            <button className="signin-button">Sign In</button>
          </form>
        </div>
      </div>
    </div>
  );
}

/*
Login.propTypes = {
  setId: PropTypes.func.isRequired,
};
*/

export default Login;
