import React, { useEffect, useState } from 'react';
import CreateNewFolderSharpIcon from '@mui/icons-material/CreateNewFolderSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import './styles/project.css';
import './styles/createProject.css';

import Navbar from '../../components/Navbar/navbar.js';
import Topnav from '../../components/Navbar/topnav.js';
import ProjectDetailTile from '../../components/Project/EditProject/projectDetailTile.js';
import AdditionalDetailsTile from '../../components/Project/EditProject/additionalDetailsTile.js';
import FileUploadTile from '../../components/Project/EditProject/fileUploadTile.js';
import SupplementalFileUpload from '../../components/Project/EditProject/supplementalFileUpload.js';
import Loading from '../../components/Loading/loading.js';

import { readyToPay } from '../../components/utils/payment.js';

function EditProject() {
  const [projectName, setProjectName] = useState(null);
  const [cost, setCost] = useState(null);
  const [sprinklered, setSprinklered] = useState(true);
  const [height, setHeight] = useState(null);
  const [existingFeet, setExistingFeet] = useState(null);
  const [proposedFeet, setProposedFeet] = useState(null);
  const [location, setLocation] = useState(null);
  const [parcel, setParcel] = useState(null);
  const [acres, setAcres] = useState(null);
  const [zoning, setZoning] = useState(null);
  const [occupancy, setOccupancy] = useState(null);
  const [client, setClient] = useState(null);
  const [permitType, setPermitType] = useState(null);
  const [description, setDescription] = useState(null);
  const [constDocs, setConstDocs] = useState(null);
  const [suppDocs, setSuppDocs] = useState(null);
  const [hooksProject, setHooksProject] = useState();
  const [hooksAdditional, setHooksAdditional] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const [load, setLoad] = useState(true);
  const [projectReady, setProjectReady] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectId } = state;

  // grab all project info on startup
  useEffect(() => {
    async function getDocs() {
      const allProjectDocs = await axios.get(
        `https://buildpermit.ai:4000/docs/${projectId}`,
        {
          withCredentials: true,
        }
      );
      if (allProjectDocs) {
        console.log('got all project docs', allProjectDocs);
      }
    }

    // set all default values for the project
    function setDefaults(data) {
      setProjectName(data.project_name);
      setCost(data.cost_estimate);
      setSprinklered(data.is_sprinklered);
      setHeight(data.building_height);
      setExistingFeet(data.existing_square_feet);
      setProposedFeet(data.proposed_square_feet);
      setLocation(data.location);
      setParcel(data.parcel_number);
      setAcres(data.acres);
      setZoning(data.zoning);
      setOccupancy(data.occupancy_type);
      setClient(data.client);
      setPermitType(data.permit_type);
      setDescription(data.description);
    }

    console.log('this is project id in edit', projectId);
    axios
      .get(`https://buildpermit.ai:4000/projects/${projectId}`, {
        withCredentials: true,
        params: { projectCountId: projectId },
      })
      .then((res) => {
        console.log('this is res', res);
        if (res.data.project.status === 'ready') {
          setProjectReady(true);
        }
        setDefaults(res.data.project);
        const setupProject = {
          projectName: res.data.project.project_name,
          cost: res.data.project.cost_estimate,
          sprinklered: res.data.project.is_sprinklered,
          height: res.data.project.building_height,
          existingFeet: res.data.project.existing_square_feet,
          proposedFeet: res.data.project.proposed_square_feet,
          location: res.data.project.location,
        };
        const setupAdditional = {
          parcel: res.data.project.parcel_number,
          acres: res.data.project.acres,
          zoning: res.data.project.zoning,
          occupancy: res.data.project.occupancy_type,
          client: res.data.project.client,
          permitType: res.data.project.permit_type,
          description: res.data.project.description,
        };
        setHooksProject(setupProject);
        setHooksAdditional(setupAdditional);
        let merged = { ...setupProject, ...setupAdditional };
        setDefaultValues(merged);
      })
      .catch((error) => {
        console.log('unable to connect to backend', error);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [projectId]);

  function edit(e) {
    e.preventDefault();

    if (!projectName) {
      alert('must set a project name');
      return;
    }

    // regex to get rid of commas in numbers
    let noCommaCost;
    let noCommaExisting;
    let noCommaProposed;
    let noCommaParcel;
    let noCommaAcres;

    if (cost && typeof cost !== 'number')
      noCommaCost = parseInt(cost.replace(/,/g, ''), 10);
    if (existingFeet && typeof existingFeet !== 'number')
      noCommaExisting = parseInt(existingFeet.replace(/,/g, ''), 10);
    if (proposedFeet && typeof proposedFeet !== 'number')
      noCommaProposed = parseInt(proposedFeet.replace(/,/g, ''), 10);
    if (parcel && typeof parcel !== 'number')
      noCommaParcel = parseInt(parcel.replace(/,/g, ''), 10);
    if (acres && typeof acres !== 'number')
      noCommaAcres = parseInt(acres.replace(/,/g, ''), 10);

    const data = {
      projectName: projectName,
      costEstimate: noCommaCost,
      isSprinklered: sprinklered,
      buildingHeight: height,
      existingSquareFeet: noCommaExisting,
      proposedSquareFeet: noCommaProposed,
      location: location,
      parcelNumber: noCommaParcel,
      acres: noCommaAcres,
      zoning: zoning,
      occupancyType: occupancy,
      permitType: permitType,
      client: client,
      description: description,
    };

    console.log('this is data to edit', data);
    console.log('this is projectId', projectId);

    axios
      .put(`https://buildpermit.ai:4000/projects/${projectId}`, data, {
        withCredentials: true,
      })
      .then(async (response) => {
        if (response.status === 200) {
          console.log('this is response', response);
          const uploaded = await uploadDocuments(
            response.data.project_id,
            response.data.project_name
          );
          if (uploaded) {
            alert('project edited successfully');
            navigate('/projects');
          } else {
            alert(
              'project edited successfully. Documents were not added/deleted'
            );
            navigate('/projects');
          }
        } else {
          alert(
            'an error has occured on our servers, we are unable to create your new project'
          );
        }
      })
      .catch((error) => {
        alert('unable to connect to citmit servers');
        console.log('unable to communicate with the backend');
        console.error(error);
      });
  }

  async function uploadDocuments(projectId, projectName) {
    if (!constDocs || !suppDocs) {
      return false;
    }

    // try this way first, if it doesnt work then try the other way
    const formData = new FormData();
    formData.append('constructionDocuments', constDocs);
    formData.append('supplementalDocuments', suppDocs);
    formData.append('projectId', projectId);
    formData.append('projectName', projectName);

    return await axios
      .post('https://buildpermit.ai:4000/docs', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          console.log('this is response after docuemnts are uploaded', res);
          return true;
        } else {
          console.log('unable to upload docs', res);
          return false;
        }
      })
      .catch((error) => {
        console.error('hit an error when uploading docs', error);
        return false;
      });
  }

  function cancelEdit(e) {
    e.preventDefault();
    navigate('/projects');
  }

  function payForProject(e) {
    e.preventDefault();
    readyToPay(e, projectId, cost);
  }

  return (
    <div id="project-container">
      <section id="main-navbar-projects">
        <Navbar />
      </section>
      <Topnav id="top-navbar-create-project" />
      <section id="create-projects-tiles-container">
        <div
          style={{ display: 'flex', flexDirection: 'row', margin: '0 1rem' }}
          id="create-project-header"
        >
          <CreateNewFolderSharpIcon id="create-project-icon" />
          <h1 id="create-project-page-title">Edit Project</h1>
          <a href="/projects" id="create-project-title-link">
            &lt; back to projects
          </a>
        </div>
        {load ? (
          <Loading subject={'loading project '} />
        ) : (
          <div>
            <section style={{ display: 'flex', flexDirection: 'row' }}>
              <ProjectDetailTile
                projectName={setProjectName}
                cost={setCost}
                sprinklered={setSprinklered}
                height={setHeight}
                existingFeet={setExistingFeet}
                proposedFeet={setProposedFeet}
                location={setLocation}
                hooksSet={hooksProject}
                id="projects-tile-projects"
              />
              <AdditionalDetailsTile
                parcel={setParcel}
                acres={setAcres}
                zoning={setZoning}
                occupancy={setOccupancy}
                client={setClient}
                permitType={setPermitType}
                description={setDescription}
                hooksSet={hooksAdditional}
                id="projects-tile-projects"
              />
            </section>
            <section style={{ display: 'flex', flexDirection: 'row' }}>
              <FileUploadTile
                constDocs={setConstDocs}
                id="projects-tile-projects"
              />
              <SupplementalFileUpload
                suppDocs={setSuppDocs}
                id="projects-tile-projects"
              />
            </section>
            <section id="create-project-button-container">
              <button
                id="create-project-button"
                style={{ backgroundColor: '#2e8bff' }}
                onClick={edit}
              >
                Finish edit
              </button>
              <button
                id="save-draft-project-button"
                style={{ backgroundColor: 'red' }}
                onClick={cancelEdit}
              >
                Cancel edit
              </button>
              {projectReady ? (
                <button id="create-project-button" onClick={payForProject}>
                  Checkout
                </button>
              ) : (
                <span style={{ display: 'none' }} />
              )}
            </section>
          </div>
        )}
      </section>
    </div>
  );
}

export default EditProject;
