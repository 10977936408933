import './styles/yesNoPopup.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function YesNoPopup({ header, projectId, setDeleted }) {
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [ableToDelete, setAbleToDelete] = useState(true);

  function deleteProject() {
    console.log('this is projectid from popup', projectId);

    axios
      .delete(`https://buildpermit.ai:4000/projects/${projectId}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res) {
          console.log('successfully deleted project');
          setDeleted(true);
          setDeletedPopup(true);
          setAbleToDelete(true);
        } else {
          console.log('unable to delete project', res);
          setAbleToDelete(false);
        }
      })
      .catch((error) => {
        console.log('unable to connect to database while deleting');
        console.error(error);
        setAbleToDelete(false);
      });
  }

  function closePopup() {
    const doc = document.getElementById('display-delete-project-popup');
    if (doc) doc.style.display = 'none';
  }

  function SuccessDelete() {
    return <div id="success-delete-container">deleted successfully!</div>;
  }

  function FailedDelete() {
    return <div id="failed-delete-container">unable to delete</div>;
  }

  useEffect(() => {
    if (deletedPopup) {
      setTimeout(() => {
        setDeletedPopup(false);
        closePopup();
      }, 1500);
    }
  }, [deletedPopup]);

  return (
    <div id="popup-yesno-container">
      {deletedPopup ? (
        ableToDelete ? (
          <SuccessDelete />
        ) : (
          <FailedDelete />
        )
      ) : (
        <div id="popup-yesno-inner-container">
          <h6 id="popup-yesno-header">{header}</h6>
          <button id="popup-yesno-yes-button" onClick={deleteProject}>
            Yes
          </button>
          <button id="popup-yesno-no-button" onClick={closePopup}>
            No
          </button>
        </div>
      )}
    </div>
  );
}

export default YesNoPopup;
