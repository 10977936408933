import './styles/verifyAccount.css';
import React, { useState } from 'react';
import axios from 'axios';

import citmitLogo from '../../images/citmitLogos/Citmit.svg';

function VerifyAccount({ setVerified }) {
  const [code, setCode] = useState();
  const [email, setEmail] = useState();

  function verifyCode(e) {
    e.preventDefault();
    if (!email) {
      document.getElementById('email-verify').style.border = '1px solid red';
      document.getElementById('helper-message').innerHTML = 'missing email';
      return;
    }

    if (!code) {
      document.getElementById('code-verify').style.border = '1px solid red';
      document.getElementById('helper-message').innerHTML =
        'missing verification code';
      return;
    }

    const data = {
      verificationCode: code,
      email: email,
    };

    axios
      .post('https://buildpermit.ai:4000/confirmation/email', data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setVerified(true);
        } else {
          alert('unable to verify code');
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.response) {
          document.getElementById('helper-message').innerHTML =
            'an error has occured with our servers';
          return;
        }
        const status = error.response.status;
        if (status === 409 || status === 401 || status === 400) {
          document.getElementById('helper-message').innerHTML =
            error.response.data;
        } else {
          document.getElementById('helper-message').innerHTML =
            'an error has occured with our servers';
        }
      });
  }

  function resendCode() {
    if (!email) {
      document.getElementById('email-verify').style.border = '1px solid red';
      document.getElementById('helper-message').innerHTML = 'missing email';
      return;
    }

    const data = {
      email: email,
    };

    axios
      .put('https://buildpermit.ai:4000/confirmation/refresh', data, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 202) {
          document.getElementById('helper-message').innerHTML = res.data;
          document.getElementById('helper-message').style.color = '#333';
        } else {
          document.getElementById('helper-message').innerHTML =
            'an error has occured with our servers';
        }
      })
      .catch((error) => {
        console.error(error);
        if (!error.response) {
          document.getElementById('helper-message').innerHTML =
            'an error has occured with our servers';
          return;
        }
        const status = error.response.status;
        if (status === 409) {
          document.getElementById('helper-message').innerHTML =
            error.response.data;
        } else {
          document.getElementById('helper-message').innerHTML =
            'an error has occured with our servers';
        }
      });
  }

  return (
    <div id="verify-account-container">
      <img src={citmitLogo} alt="citmit logo" id="verify-citmit-logo" />
      <h1 id="verify-account-header">
        Enter verification number sent to email <br /> or click 'resend code' to
        send another
      </h1>
      <form id="verification-input-container" onSubmit={verifyCode}>
        <input
          type="email"
          placeholder="Email"
          className="verification-code-input"
          id="email-verify"
          required
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="text"
          placeholder="Verification Code"
          className="verification-code-input"
          id="code-verify"
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
        <section id="helper-message-container">
          <span id="helper-message"></span>
        </section>
        <div id="verification-button-container">
          <button id="verify-code">Verify</button>
          <p id="resend-code" onClick={resendCode}>
            Resend Code
          </p>
          <a href="/" id="account-confirm-to-signin">
            &#60; Back to Sign in{' '}
          </a>
        </div>
      </form>
    </div>
  );
}

export default VerifyAccount;
